import {computed, reactive, ref, watch} from 'vue';
import {
  getBookGroupSum,
  getGroupSum,
  getLevelText,
  learningTalents,
} from '@/assets/js/modules/mypage/talent-common';
import {useAlert} from '@/assets/js/modules/common/alert';
import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {
  ACT_GET_SOC_BOARD_WRITE_STAT_LIST,
  ACT_GET_SOC_BOARD_WRITE_STAT_VICE_LIST,
} from '@/store/modules/ssl/ssl-index';
import {noneRewardCds, sslCodes} from '@/assets/js/modules/ssl/ssl-common';
import {closeToggles, getListFunc, releaseArr} from '@/assets/js/ui.init';
import {
  // eslint-disable-next-line no-unused-vars
  ACT_GET_LRN_CRSE_WORK_ABILITY_GROUP_LIST,
  ACT_GET_LRN_CRSE_WORK_ABILITY_GROUP_VICE_LIST,
  ACT_GET_LRN_CRSE_WORK_GOAL_LIST_BY_LRNER_ID,
  ACT_GET_LRN_CRSE_WORK_GOAL_VICE_LIST,
  ACT_GET_LRN_SCORE_LIST,
  ACT_GET_LRN_SCORE_VICE_LIST,
  ACT_INSERT_LRN_CRSE_WORK_GOALS,
  ACT_UPDATE_LRN_CRSE_WORK_GOALS,
} from '@/store/modules/talent/talent';
import {ACT_GET_AUTH_DEPT} from '@/store/modules/auth/auth';
import {
  getCheckItems,
  getItem,
  isSuccess,
  lengthCheck,
  paginate,
  setParams,
  timestampToDateFormat,
} from '@/assets/js/util';
import {
  downloadExcelFile,
  downloadExcelFileByItems,
} from '@/assets/js/modules/common/excel-common';

export const viceMainSetup = () => {
  const store = useStore();
  const isMobile = computed(() => store.state.auth.isMobile);

  const route = useRoute();
  const router = useRouter();

  const {showLoading, hideLoading} = useAlert();
  const link = ref('status');
  const session = computed(() => store.state.auth.session);

  const openDetail = computed(() => {
    if (isMobile.value) {
      return route.query.modal === 'detail';
    }
    return false;
  });

  const openCoaching = computed(() => {
    if (isMobile.value) {
      return route.query.modal === 'coaching';
    }
    return false;
  });

  const abilities = ref([]);
  const goals = ref([]);
  const selfs = ref([]);
  const allAbilities = ref([]);

  const readies = reactive({
    ability: false,
    goal: false,
    all: false,
    self: false,
    hrd: false,
    group: false,
    // knowledge: false
  });

  const targetDeptCd = ref('');
  const dept = reactive({
    deptCd: '',
    upDeptCd: '',
    deptNm: '',
    deptLvl: '',
    children: null,
    upDeptNms: [],
    models: []
  });

  const depts = ref([]);

  const write = reactive({
    total: [],
    dept: []
  });

  const grape = reactive({
    total: [],
    dept: []
  });


  const hrdMlgs = ref([]);
  const groups = ref([]);
  const knowledges = ref([]);


  const getBestAbility = (abilities) => {
    if(abilities){
      const max = Math.max(...abilities.map(x => x.totalScore));
      return abilities.find(x => x.totalScore > 0 && x.totalScore === max) || {crseWorkDtlSn: 0};
    }
    return {
      crseWorkDtlSn: 0
    }
  }

  const completedCoaching = (item) => {
    const coach = goals.value.find(x => x.crseWorkGoalsSn === item.crseWorkGoalsSn);
    if(coach){
      Object.keys(item).forEach(key => {
        coach[key] = item[key];
      });
    }else{
      goals.value.push(item);
    }

    if (isMobile.value) {
      hideModal();
    }
  }

  const closeDeptToggles = (index) => {
    dept.models.forEach((m, idx) => {
      if(index !== idx) m.toggle = false;
    });
  }

  const selectedDept = (index) => {
    dept.models.forEach((m, idx) => {
      if(index < idx) m.deptCd = '';
    });
    const deptCds = dept.models.filter(x => x.deptCd).map(x => x.deptCd);
    const changeDeptCd = deptCds.length > 0 ? deptCds[deptCds.length - 1] : dept.deptCd;
    if(changeDeptCd !== targetDeptCd.value) {
      targetDeptCd.value = changeDeptCd;
      showLoading();
      readies.ability = false;
      readies.goal = false;

      // 처리...
      readies.self = false;
      readies.hrd = false;
      readies.group = false;
      // readies.knowledge = false;

      write.dept = [];
      write.total = [];
      grape.dept = [];
      grape.total = [];

      hrdMlgs.value = [];
      groups.value = [];
      // knowledges.value = [];
      getAllList();
    }
  }

  const totalReadies = reactive({
    hrd: false,
    group: false,
    // knowledge: false,
    ssl: false,
  });

  const getSSLWriteStatList = () => {
    store.dispatch(`ssl/${ACT_GET_SOC_BOARD_WRITE_STAT_LIST}`, {totalYn: 'Y', deptYn: 'Y', deptCd: targetDeptCd.value}).then(res => {
      if(res){
        Object.keys(grape).forEach(key => {
          write[key] = res[key] ? res[key] : [];
          setGrape(write[key], grape[key]);
        });
      }
      readies.self = true;
    });

    const params = {deptCd: targetDeptCd.value};

    getListFunc(`talent/${ACT_GET_LRN_SCORE_VICE_LIST}`, {contents: 'hrd', params}, hrdMlgs, null, () => {readies.hrd = true});
    // getListFunc(`talent/${ACT_GET_LRN_SCORE_VICE_LIST}`, {contents: 'knowledge', params}, knowledges, null, () => {readies.knowledge = true;});
    getListFunc(`talent/${ACT_GET_LRN_SCORE_VICE_LIST}`, {contents: 'group', params}, groups, null, () => {readies.group = true;});
  }

  const currentYear = new Date().getFullYear() + ""
  const targetSSLCodes = sslCodes[currentYear];

  const setGrape = (target, arr) => {
    if(target){
      Object.keys(targetSSLCodes).forEach(k => {
        if(k !== 'trivia' && k !== 'book') {
          // if( k === 'book'){
          //   arr.push(getBookGroupSum(target, targetSSLCodes[k].refGroup.map(x => x.lrnTrgtGrpMstSn)));
          // }else{
            arr.push(getGroupSum(target, targetSSLCodes[k].group.map(x => x.lrnTrgtGrpMstSn)));
          }
        // }
      });
    }
  }

  const etcGrape = reactive({
    hrd: {
      total: 0,
    },
    selfGa: {
      total: 0,
    },
    selfgi: {
      total: 0,
    },
    selIct: {
      total: 0,
    },
    selfJa: {
      total: 0,
    },
    group: {
      total: 0,
    }
  });
  const keys = ['total'];
  // eslint-disable-next-line no-unused-vars
  const getLearnScoreTotalList = (contents) => {
    keys.forEach(k => {
      etcGrape[contents][k] = 0;
    });


    store.dispatch(`talent/${ACT_GET_LRN_SCORE_LIST}`, {contents, params: {totalYn: 'Y'}}).then(res => {
      if(res){
        const k = 'total';
        if(res[k] && res[k].length > 0) {
          const item = res[k][0];
          // if (contents === 'knowledge') {
          //   if (item && item.targetCnt > 0 && item.learnCnt > 0) {
          //     etcGrape[contents][k] = (item.learnCnt / item.targetCnt) * 5;
          //   } else {
          //     etcGrape[contents][k] = 0;
          //   }
          // } else {
            if (item && item.targetCnt > 0 && item.sumMlgValue > 0) {
              etcGrape[contents][k] = item.sumMlgValue / item.targetCnt;
            } else {
              etcGrape[contents][k] = 0;
            }
          // }
        }
        totalReadies[contents] = true;
      }
    })
  }

  const getAllList = () => {
    store.dispatch(`talent/${ACT_GET_LRN_CRSE_WORK_ABILITY_GROUP_VICE_LIST}`, {deptCd: targetDeptCd.value}).then(res => {
      const items = getCheckItems(res);
      abilities.value = items.map(x => ({...x, bestAbility: getBestAbility(x.abilities)}));
      readies.ability = true;
      hideLoading();
    });
    // getListFunc(`talent/${ACT_GET_LRN_CRSE_WORK_ABILITY_GROUP_VICE_LIST}`, {deptCd: targetDeptCd.value}, abilities, null, () => {
    //   abilities.value = abilities.value.map(x => ({...x, bestAbility: getBestAbility(x.abilities)}));
    //   readies.ability = true;
    //   hideLoading();
    // });
    getListFunc(`talent/${ACT_GET_LRN_CRSE_WORK_GOAL_VICE_LIST}`, {deptCd: targetDeptCd.value}, goals, null, () => {readies.goal = true;});
    getListFunc(`ssl/${ACT_GET_SOC_BOARD_WRITE_STAT_VICE_LIST}`, {deptCd: targetDeptCd.value}, selfs, null);

    getSSLWriteStatList();
  }

  if(session.value.vice){
    showLoading();
    store.dispatch(`auth/${ACT_GET_AUTH_DEPT}`).then(res => {
      if(lengthCheck(res)) {
        const item = getItem(res);
        setParams(dept, item);
        const allArr = [item];
        releaseArr(item.children, allArr, (t) => t);
        depts.value = allArr;

        const result = [];
        releaseArr(item.children, result, (t) => t.deptLvl);

        for(let i = item.deptLvl; i < (Math.max(...result)); i++){
          dept.models.push({deptLvl: (i + 1), deptCd: '', toggle: false});
        }
      }

      getAllList();

      // TODO: 어디다 쓰는 물건인고??
      getListFunc(`talent/${ACT_GET_LRN_CRSE_WORK_ABILITY_GROUP_LIST}`, {allYn: 'Y'}, allAbilities, null, () => {readies.all = true});
      // readies.all = true;  //callback만 지원
      getLearnScoreTotalList('hrd');
      // getLearnScoreTotalList('knowledge');
      getLearnScoreTotalList('group');
    });
  }else{
    router.push('/main');
  }

  // mobile 용
  const targetAbilities = ref([]);
  const targetAbility = ref({});
  const showDetail = target => {
    targetAbilities.value = target;
    router.push({query: {modal: 'detail'}});
  }
  const currentQuery = computed(() => route.query);
  const hideModal = () => {
    router.push({query: {...currentQuery.value, modal: null}});
  }


  const showCoaching = target => {
    targetAbility.value = target;
    router.push({query: {...currentQuery.value, modal: 'coaching'}});
  }

  const modalTitle = computed(() => {
    if (route.query.modal === 'detail') {
      return '개인별 역량개발 상세'
    } else if (route.query.modal === 'coaching'){
      return '코칭관리'
    }
    return '-'
  })



  return {
    link,
    dept,
    depts,
    abilities,
    goals,
    selfs,
    allAbilities,
    write,
    grape,
    etcGrape,
    readies,
    targetAbilities,
    targetAbility,
    hrdMlgs,
    knowledges,
    groups,
    selectedDept,
    closeDeptToggles,
    completedCoaching,
    openDetail,
    openCoaching,
    modalTitle,
    showDetail,
    hideModal,
    showCoaching,
    totalReadies,
  }
}

export const viceRouteSetup = (props, {emit}) => {
  const links = ref([
    {
      key: 'status',
      name: '역량개발현황'
    },
    {
      key: 'coaching',
      name: '코칭관리'
    }
  ]);

  const changeLink = (key) => {
    if(props.link !== key){
      emit('update:link', key);
    }
  }

  const getDeptOptions = (model, index) => {
    if(index === 0){
      return props.depts.filter(x => x.deptLvl === model.deptLvl);
    }else{
      const upDeptCd = props.dept.models[index - 1].deptCd;
      if(upDeptCd){
        return props.depts.filter(x => x.deptLvl === model.deptLvl && x.upDeptCd === upDeptCd);
      }else{
        return [];
      }
    }
  }

  const closeDeptToggle = (index) => {
    emit('closeToggles', index);
  }

  const selectedDept = (index) => {
    emit('selectedDept', index);
  }


  return {
    links,
    getDeptOptions,
    changeLink,
    selectedDept,
    closeDeptToggle
  }
}

export const viceBestSetup = (props) => {
  const store = useStore();
  const isMobile = computed(() => store.state.auth.isMobile);

  const chartOptions = {
    chart: { width: isMobile.value ? 320 : 400, height: isMobile.value ? 320 : 400 },
    series: {
      dataLabels: {
        visible: false,
      },
      first: {
        radiusRange: {
          inner: '50%',
          outer: '80%'
        },
      }
    },
    legend: {
      visible: false
    },
    tooltip: {
      template: (model) => {
        if(model.data[0].label){
          return `<div style="border: solid 1px; background: #FFFFFF;width: 140px;padding: 0 5px;text-align: center;"><div class="toastui-chart-tooltip-series-wrapper" style="font-weight: normal; color:black;  font-size: 12px; "><div class="toastui-chart-tooltip-series"><span class="toastui-chart-series-name"><span class="toastui-chart-name" style="color:black;">${model.data[0].label}</span></span><span class="toastui-chart-series-value">${model.data[0].value === 0.1 ? 0 : model.data[0].value}</span></div></div></div>`;
        }
        return '';
      }
    },
    exportMenu: {
      visible: false
    },
    theme: {
      series: {
        first: {
          colors: [
            '#a4a3a3',
            '#84888B',
            '#e8e8e8',
            '#8D744A',
            '#FFCC00',
            '#daae97',
            '#2faaed',
            '#fd94a0',
            '#9488DB'
          ],
        },
      },
    },
    usageStatistics: false
  }

  const chartSeries = computed(() => {
    const noCount = props.abilities.filter(y => y.bestAbility.crseWorkDtlSn === 0).length;
    const result = [
      {
        name: '없음',
        data: noCount === 0 ? 0.1 : noCount
      }
    ];

    learningTalents.forEach(x => {
      const count = props.abilities.filter(y => y.bestAbility.crseWorkDtlNm === x.key).length;
      // const item = {name: x.name, data: count};
      const item = {name: x.name, data: count === 0 ? 0.1 : count};
      result.push(item);
    })

    return [{
      name: 'first',
      data: result
    }]
  });

  const hopGoals = computed(() => props.goals.filter(x => x.goalsDivCd === '01'));


  return {
    chartOptions,
    chartSeries,
    hopGoals

  }
}

export const viceAbilitySetup = (props, {emit}) => {
  const store = useStore();
  const isMobile = computed(() => store.state.auth.isMobile);

  const tooltip = ref(false);
  const abilityRows = computed(() => {
    const result = [];
    props.abilities.forEach(x => {
      result.push(...x.abilities);
    });
    return result;
  });

  const detail = reactive({
    toggle: false,
    targetAbilities: []
  });

  const tableLevels = [
    {
      key: 'expert',
      name: isMobile.value ? '전문가' : '전문가(Expert)'
    },
    {
      key: 'advanced',
      name: isMobile.value ? '고급' : '고급(Advanced)'
    },
    {
      key: 'intermediate',
      name: isMobile.value ? '중급' : '중급(Intermediate)'
    },
    {
      key: 'beginner',
      name: isMobile.value ? '초급' : '초급(Beginner)'
    },
    {
      key: 'no',
      name: '무등급'
    },
    {
      key: 'sum',
      name: '합계'
    }
  ];

  const getLevelFilterFunc = (level) => {
    if(level === 'sum') {
      return x => (x.totalScore >= 0)
    } else if(level === 'no'){
      return x => (x.totalScore < 10)
    }else if(level === 'beginner'){
      return x => (x.totalScore >= 10 && x.totalScore < 20)
    }else if(level === 'intermediate'){
      return x => (x.totalScore >= 20 && x.totalScore < 30)
    }else if(level === 'advanced'){
      return x => (x.totalScore >= 30 && x.totalScore < 40)
    }else{
      return x => (x.totalScore >= 40)
    }
  }

  const getLevelWorksCount = (key, level) => {
    return abilityRows.value.filter(x => x.crseWorkDtlNm === key).filter(getLevelFilterFunc(level)).length;
  }

  const getLevelWorksItems = (level) => {
    return learningTalents.map(x => ({
      name: x.name,
      key: x.key,
      count: getLevelWorksCount(x.key, level)
    }));
  }

  const tableAbilities = computed(() => {
    return tableLevels.map(x => ({
      name: x.name,
      items: getLevelWorksItems(x.key)
    }));
  });

  const chartLevels = [
    {
      key: 'no',
      name: '무등급',
      color: 'value-vso'
    },
    {
      key: 'beginner',
      name: isMobile.value ? '초급': '초급(Beginner)',
      color: 'value-yellow'
    },
    {
      key: 'intermediate',
      name: isMobile.value ? '중급': '중급(Intermediate)',
      color: 'value-vsr'
    },
    {
      key: 'advanced',
      name: isMobile.value ? '고급': '고급(Advanced)',
      color: 'value-vsb'
    },
    {
      key: 'expert',
      name: isMobile.value ? '전문가': '전문가(Expert)',
      color: 'value-bb'
    },
  ];

  const getLevelDistributionCount = (filtered, level) => {
    if(filtered.length > 0){
      return (filtered.filter(getLevelFilterFunc(level)).length/filtered.length * (isMobile.value ? 170 : 200));
    }
    return 0;
  }


  const getDistributionItems = (key) => {
    const filtered = abilityRows.value.filter(x => x.crseWorkDtlNm === key);
    return chartLevels.map(x => ({
      ...x,
      height: getLevelDistributionCount(filtered, x.key)
    }));
  }

  const chartAbilities = computed(() => {
    return learningTalents.map(x => ({
      name: x.name,
      items: getDistributionItems(x.key)
    }));
  });

  const chartYAxiosItems = computed(() => {
    const share = parseInt(props.abilities.length/4);
    const result = [props.abilities.length];
    for(let i = 3; i > 0; i--){
      result.push(parseInt(share*i));
    }
    result.push(0);
    return result;
  });

  const showDetail = (item, index) => {
    if (isMobile.value) {
      emit('showDetail', props.abilities.filter(x => {
        return x.abilities.filter(y => y.crseWorkDtlNm === item.key).filter(getLevelFilterFunc(tableLevels[index].key)).length > 0
      }));
    } else {
      detail.targetAbilities = props.abilities.filter(x => {
        return x.abilities.filter(y => y.crseWorkDtlNm === item.key).filter(getLevelFilterFunc(tableLevels[index].key)).length > 0
      });
      detail.toggle = true;
    }
  }

  const abilityGrades = computed(() => {
    return learningTalents.map(x => {
      const all = props.allAbilities.find(y => y.crseWorkDtlNm === x.key);
      const filtered = abilityRows.value.filter(y => y.crseWorkDtlNm === x.key).map(x => x.totalScore);

      const allScore = all ? all.totalScore : 0;
      const filteredScore = filtered.length > 0 ? filtered.reduce((a, b) => (a+b), 0) / filtered.length : 0;
      const diff = filteredScore - allScore;

      if(diff <= -21){
        return 'marker-red';
      }else if(diff > -21 && diff <= 20){
        return 'marker-silver';
      }else{
        return 'marker-yellow';
      }
    })

  })

  return {
    tooltip,
    detail,
    tableAbilities,
    chartAbilities,
    chartYAxiosItems,
    abilityGrades,
    showDetail
  }
}

export const viceAbilityAceTableSetup = () => {
  const getScoreItems = (item) => {
    return learningTalents.map(x => {
      const score = {
        totalScore: 0,
        level: '-',
      }
      const target = item.abilities.find(y => y.crseWorkDtlNm === x.key);
      if(target){
        score.totalScore = target.totalScore;
        score.level = getLevelText(target.totalScore);
      }
      return score;
    });
  }

  return {
    getScoreItems
  }
}

export const viceAbilitySelfTableSetup = (props) => {
  const headerDom = ref(null);

  const currentYear = new Date().getFullYear() + ""

  const renderAbilities = computed(() => {
    return props.targetAbilities.map(x => {
      const sslResult = [];
      const selfTarget = props.selfs.find(y => y.lrnerId === x.lrnerId);

      const onHrdMlgTarget = props.hrdMlgs.find(y => y.lrnerId === x.lrnerId && y.knwlFlag === '1');
      const offHrdMlgTarget = props.hrdMlgs.find(y => y.lrnerId === x.lrnerId && y.knwlFlag === '2');
      const knowledgeTarget = props.knowledges.find(y => y.lrnerId === x.lrnerId);
      const groupTarget = props.groups.find(y => y.lrnerId === x.lrnerId);

      const target = selfTarget ? selfTarget.stats : [];
      const targetSSLCodes = sslCodes[currentYear];

      Object.keys(targetSSLCodes).forEach(k => {
          sslResult.push(k === 'book' ? getBookGroupSum(target, targetSSLCodes[k].group.map(y => y.lrnTrgtGrpMstSn)) : getGroupSum(target, targetSSLCodes[k].group.map(y => y.lrnTrgtGrpMstSn)));
      });

      const totalScore = (() => {
        let result = sslResult.length > 0 ? (sslResult.reduce((accumulator, a) => (accumulator + a)) * 5) : 0;
        if (onHrdMlgTarget) result += onHrdMlgTarget.sumMlgValue;
        if (offHrdMlgTarget) result += offHrdMlgTarget.sumMlgValue;
        if (knowledgeTarget) result += knowledgeTarget.sumGetValue;
        if (groupTarget) result += groupTarget.learnCnt * 5;
        return result;
      })();

      const sslTotalScore = (() => {
        let result = sslResult.length > 0 ? (sslResult.reduce((accumulator, a) => (accumulator + a)) * 5) : 0;
        if (knowledgeTarget) result += knowledgeTarget.sumGetValue;
        if (groupTarget) result += groupTarget.learnCnt * 5;
        return result;
      })();


      return {
        lrnerNm: x.lrnerNm,
        ssl: sslResult,
        onMlg: onHrdMlgTarget ? onHrdMlgTarget.sumMlgValue : 0,
        offMlg: offHrdMlgTarget ? offHrdMlgTarget.sumMlgValue : 0,
        groupScore: groupTarget ? groupTarget.learnCnt : 0,
        // knowledgeScore: knowledgeTarget ? knowledgeTarget.sumGetValue : 0,
        totalScore,
        sslTotalScore,
      };
    });
  });


  return {
    headerDom,
    renderAbilities
  }
}

export const viceTargetAbilitySetup = (props, {emit}) => {
  const currentYear = new Date().getFullYear() + "";

  const view = reactive({
    current: 'profile',
    tabs: [{name: 'Profile', key: 'profile'},{name: '역량개발 학습지수', key: 'ace'},{name: '자기주도 학습지수', key: 'self'}]
  });

  const currentTab = computed(() => {
    const target = view.tabs.find(x => x.key === view.current);
    return target ? target : {name: '-'};
  });

  const selfDom = ref(null);


  const getBestEtc = (etc) => {
    if (etc) {
      let etcArr = etc.split(',');
      etcArr = Array.from(new Set(etcArr.map(x => x.trim()).filter(x => x !== '')));
      if (etcArr.length > 0) {
        return etcArr.join(',');
      }
    }
    return '-';
  }


  const renderAbilities = computed(() => props.targetAbilities.map(x => {
    const hope = props.goals.find(y => y.lrnerId === x.lrnerId && y.goalsDivCd === '01');

    return {
      ...x,
      hopeAbility: hope || {crseWorkDtlSn: 0},
      etc: x.bestAbility.crseWorkDtlNm && x.bestAbility.crseWorkDtlNm.startsWith('DT') ? getBestEtc(x.bestAbility.crseWorkLrnFldNmLst) : '-'
    }
  }));

  const paging = reactive({
    pageNo: 1,
    pageSize: 10
  });

  const pagingAbilities = computed(() => {
    return paginate(renderAbilities.value, paging.pageNo, paging.pageSize);
  });

  const pagingFunc = (pageNo) => {
    paging.pageNo = pageNo;
  }

  const getViewItems = (current) => {
    if(current === 'profile'){
      return props.targetAbilities.map(x => {
        const hopeGoals = props.goals.find(y => y.lrnerId === x.lrnerId && y.goalsDivCd === '01');
        return {
          ...x,
          curtJbgdPromoYmd : (x.curtJbgdPromoYmd && x.curtJbgdPromoYmd.length > 3 ? x.curtJbgdPromoYmd.substr(0, 4) : '-'),
          hope: hopeGoals ? hopeGoals.crseWorkDtlNm : '-',
          best: x.bestAbility.crseWorkDtlNm ? x.bestAbility.crseWorkDtlNm : '-',
          etc: x.bestAbility.crseWorkDtlNm && x.bestAbility.crseWorkDtlNm.startsWith('DT') ? getBestEtc(x.bestAbility.crseWorkLrnFldNmLst) : '-'
        }
      });
    }else if(current === 'ace'){
      return props.targetAbilities.map(x => {
        const result = {
          lrnerNm: x.lrnerNm,
          lrnerId: x.lrnerId,
          jbgdCd: x.jbgdCd,
        }
        learningTalents.map(y => {
          const score = {
            key: y.key,
            totalScore: 0,
            level: '-',
          }
          const target = x.abilities.find(z => z.crseWorkDtlNm === y.key);
          if(target){
            score.totalScore = target.totalScore;
            score.level = getLevelText(target.totalScore);
          }
          return score;
        }).forEach(talent => {
          result[talent.key] = talent.level;
        });
        return result;
      })
    }else if(current === 'self'){
      return props.targetAbilities.map(x => {
        const sslResult = [];
        const selfTarget = props.selfs.find(y => y.lrnerId === x.lrnerId);

        const onHrdMlgTarget = props.hrdMlgs.find(y => y.lrnerId === x.lrnerId && y.knwlFlag === '1');
        const offHrdMlgTarget = props.hrdMlgs.find(y => y.lrnerId === x.lrnerId && y.knwlFlag === '2');

        const knowledgeTarget = props.knowledges.find(y => y.lrnerId === x.lrnerId);
        const groupTarget = props.groups.find(y => y.lrnerId === x.lrnerId);

        const target = selfTarget ? selfTarget.stats : [];
        const targetSSLCodes = sslCodes[currentYear];
        Object.keys(targetSSLCodes).forEach(k => {
          // if(k !== 'trivia') {
          sslResult.push(k === 'book' ? getBookGroupSum(target, targetSSLCodes[k].group.map(y => y.lrnTrgtGrpMstSn)) : getGroupSum(target, targetSSLCodes[k].group.map(y => y.lrnTrgtGrpMstSn)));
          // }
        });

        const totalScore = (() => {
          let result = sslResult.length > 0 ? (sslResult.reduce((accumulator, a) => (accumulator + a)) * 5) : 0;
          if (onHrdMlgTarget) result += onHrdMlgTarget.sumMlgValue;
          if (offHrdMlgTarget) result += offHrdMlgTarget.sumMlgValue;

          if (knowledgeTarget) result += knowledgeTarget.sumGetValue;
          if (groupTarget) result += groupTarget.learnCnt * 5;
          return result;
        })();

        const sslTotalScore = (() => {
          let result = sslResult.length > 0 ? (sslResult.reduce((accumulator, a) => (accumulator + a)) * 5) : 0;
          if (knowledgeTarget) result += knowledgeTarget.sumGetValue;
          if (groupTarget) result += groupTarget.learnCnt * 5;
          return result;
        })();

        return {
          lrnerNm: x.lrnerNm,
          lrnerId: x.lrnerId,
          jbgdCd: x.jbgdCd,
          ssl: sslResult,
          onMlg: onHrdMlgTarget ? onHrdMlgTarget.sumMlgValue : 0,
          offMlg: offHrdMlgTarget ? offHrdMlgTarget.sumMlgValue : 0,
          groupScore: groupTarget ? groupTarget.learnCnt : 0,
          // knowledgeScore: knowledgeTarget ? knowledgeTarget.sumGetValue : 0,
          totalScore,
          sslTotalScore,
        };
      });
    }
  }

  const closeModal = () => {
    emit('update:toggle', false);
  }

  const headersMap = {
    profile: [
      {key: 'no', name: 'No.'},
      {key: 'lrnerNm', name: '구분'},
      {key: 'lrnerId', name: '직번'},
      {key: 'jbgdCd', name: '직급'},
      {key: 'deptNm', name: '소속'},
      {key: 'curtJbgdPromoYmd', name: '최근승격년'},
      {key: 'hope', name: '희망 학습분야'},
      {key: 'best', name: '우수 학습분야'},
      {key: 'etc', name: '비고'},
    ],
    ace: [
      {key: 'no', name: 'No.'},
      {key: 'lrnerNm', name: '구분'},
      {key: 'lrnerId', name: '직번'},
      {key: 'jbgdCd', name: '직급'},
      {key: '기업금융', name: '기업금융'},
      {key: '자산관리', name: '자산관리'},
      {key: 'DT기획', name: 'DT기획'},
      {key: 'DT개발', name: 'DT개발'},
      {key: 'IB', name: 'IB'},
      {key: '자본시장', name: '자본시장'},
      {key: '리스크', name: '리스크'},
      {key: '글로벌', name: '글로벌'},
    ]
  }

  const downloadExcel = () =>{
    const current = view.tabs.find(x => x.key === view.current);
    if(current){
      if(current.key === 'self'){
        const table = `<table border="1"><colgroup><col><col style="width:102px"><col><col><col><col><col><col><col><col><col><col><col><col><col style="width:52px"></colgroup><thead><tr><th bgcolor="#d3d3d3"><span>No.</span></th><th bgcolor="#d3d3d3"><span>성명</span></th><th bgcolor="#d3d3d3"><span>직원번호</span></th><th bgcolor="#d3d3d3"><span>직급</span></th><th bgcolor="#d3d3d3"><span>HRD 클라우드 마일리지(직무)</span></th><th bgcolor="#d3d3d3"><span>HRD 클라우드 마일리지(직무 외)</span></th><th bgcolor="#d3d3d3"><span>학습노트 (셀프러닝)</span></th><th bgcolor="#d3d3d3"><span>학습노트 (그룹러닝)</span></th><th bgcolor="#d3d3d3"><span>학습노트 (지식e러닝)</span></th><th bgcolor="#d3d3d3"><span>학습노트 (HRD클라우드러닝)</th><th bgcolor="#d3d3d3"><span>학습노트 (북러닝)</span></th><th bgcolor="#d3d3d3"><span>그룹러닝 학습모임</span></th><th bgcolor="#d3d3d3"><span>지식e러닝 진단평가</span></th><th bgcolor="#d3d3d3"><span>SSL 소계</span></th><th bgcolor="#d3d3d3"><span>합계</span></th></tr></thead><tbody>${getViewItems(view.current). map(( x, idx) => `<tr><td><div><span>${idx + 1}</span></div></td><td><div><span>${x.lrnerNm}</span></div></td><td><div><span>${x.lrnerId}</span></div></td><td><div><span>${x.jbgdCd}</span></div></td><td><span>${x.onMlg}M</span></td><td><span>${x.offMlg}M</span></td>${x.ssl.map( s => `<td><span>${s}건(${(s * 5)}점)</span></td>`). join( '')}<td><span>${x.groupScore > 0 ? `${x.groupScore.toFixed(0)}회(${(x.groupScore * 5).toFixed(0)}점)`:'0회(0점)'}</span></td><td><span>${x.knowledgeScore}점</span></td><td><span>${x.sslTotalScore}점</span></td><td><span>${x.totalScore}점</span></td></tr>`). join('')}</tbody></table>`;
        downloadExcelFile(`Talent-${current.name}.xls`, null, table);
      }else{
        downloadExcelFileByItems(`Talent-${current.name}.xls`, null, headersMap[view.current], getViewItems(view.current));
      }

    }
  }


  return {
    view,
    currentTab,
    selfDom,
    pagingAbilities,
    paging,
    closeModal,
    downloadExcel,
    pagingFunc
  }
}

export const viceSSLSetup = (props) => {
  const store = useStore();
  const isMobile = computed(() => store.state.auth.isMobile);
  const tooltip = ref(false);
  const chartOptions = {
    chart: { width: isMobile.value ? 350 : 400, height: isMobile.value ? 300 : 400 },
    series: {
      clockwise: false,
      dataLabels: {
        visible: false,
      },
      first: {
        radiusRange: {
          inner: '55%',
          outer: '70%'
        }
      },
      second: {
        radiusRange: {
          inner: '75%',
          outer: '90%'
        }
      }
    },
    legend: {
      visible: false
    },
    tooltip: {
      template: (model) => {
        if(model.data[0].label){
          return `<div style="background: #FFFFFF; border: solid 1px; width: 140px;padding: 0 5px;text-align: center;"><div class="toastui-chart-tooltip-series-wrapper" style="font-weight: normal; color:black; font-size: 12px; "><div class="toastui-chart-tooltip-series"><span class="toastui-chart-series-name"><i class="toastui-chart-icon" style="background: ${model.data[0].color}"></i><span class="toastui-chart-name" style="color:black;">${model.data[0].category}</span></span><span class="toastui-chart-series-value">${model.data[0].value}</span></div></div></div>`;
        }
        return '';
      }
    },
    exportMenu: {
      visible: false
    },
    theme: {
      series: {
        first: {
          colors: ['#84888B', '#e8e8e8'],
        },
        second: {
          colors: ['#FFBC00', '#e8e8e8'],
        }
      },
    },
    usageStatistics: false
  }

  const currentYear = new Date().getFullYear() + ""
  const targetSSLCodes = sslCodes[currentYear];

  const onMlgScore = computed(() => {
    if (props.abilities.length > 0) {
      const filtered = props.hrdMlgs.filter(x => x.knwlFlag === '1');
      if (filtered.length > 0) {
        return filtered.map(x => x.sumMlgValue).reduce((accumulator, a) => (accumulator + a)) / props.abilities.length;
      }
    }
    return 0;
  });

  const offMlgScore = computed(() => {
    if (props.abilities.length > 0) {
      const filtered = props.hrdMlgs.filter(x => x.knwlFlag === '2');
      if (filtered.length > 0) {
        return filtered.map(x => x.sumMlgValue).reduce((accumulator, a) => (accumulator + a)) / props.abilities.length;
      }
    }
    return 0;
  });

  const knowledgeScore = computed(() => {
    if (props.abilities.length > 0 && props.knowledges.length > 0) {
      return (props.knowledges.map(x => x.sumGetValue).reduce((accumulator, a) => (accumulator + a))) / props.abilities.length;
    }
    return 0;
  });

  const groupScore = computed(() => {
    if (props.abilities.length > 0 && props.groups.length > 0) {
      // console.log(props.groups.length)

      return props.groups.map(x => x.learnCnt).reduce((accumulator, a) => (accumulator + a))/ props.groups.length;
    }
    return 0;
  });

  const totalScore = computed(() => {
    let result = 0;
    if(props.write.dept.length > 0){
      result += props.grape.dept.reduce((accumulator, a) => (accumulator + a)) * 5;
    }
    result += (onMlgScore.value + offMlgScore.value);
    // result += knowledgeScore.value;
    result += groupScore.value * 5;
    return result;
  });

  const sslTotalScore = computed(() => {
    let result = 0;
    if(props.write.dept.length > 0){
      result += props.grape.dept.reduce((accumulator, a) => (accumulator + a)) * 5;
    }
    // result += knowledgeScore.value;
    result += groupScore.value * 5;
    return result;
  });

  const etcSum = (position) => {
    let sum = 0;
    Object.keys(props.etcGrape).forEach(key => {
      sum += props.etcGrape[key][position];
    });
    return sum;
  }

  const totalTotalScore = computed(() => {
    return (props.grape.total.length > 0 ? props.grape.total.reduce((a, b) => a + b, 0) : 0) * 5 + etcSum('total');
  });

  const grapeStatus = computed(() => {
    const total = parseInt(totalTotalScore.value);
    const dept = parseInt(totalScore.value);

    return [
      {
        name: 'first',
        data: [
          {
            name: 'first',
            data: total,
          },
          {
            name: '',
            data: (730 - total),
          },
        ],
      },
      {
        name: 'second',
        data: [
          {
            name: 'second',
            data: dept,
          },
          {
            name: '',
            data: (730 - dept),
          },
        ],
      },
    ];
  });


  const getScore = (value, key) => {
    let count;
    if( key === 'book'){
      count = getBookGroupSum(props.write.dept, value.group.map(x => x.lrnTrgtGrpMstSn))
    }else{
      count = getGroupSum(props.write.dept, value.group.map(x => x.lrnTrgtGrpMstSn))
    }
    if(count > 0){
      return `${count}건 <br/>(${count * 5}점)`
    }
    return '-';
  }


  return {
    tooltip,
    grapeStatus,
    chartOptions,
    onMlgScore,
    offMlgScore,
    knowledgeScore,
    groupScore,
    totalScore,
    totalTotalScore,
    sslTotalScore,
    getScore,
    noneRewardCds,
    targetSSLCodes
  }
}

export const viceTalentCoachingSetup = (props, {emit}) => {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();


  const isMobile = computed(() => store.state.auth.isMobile);
  const subTab = reactive({
    current: computed(() => {
      return route.query.sub || 'Coaching'
    }),
    // tabs: ['Profile', 'Coaching']
    tabs: ['Coaching']
  })

  const changeSub = sub => {
    router.push({query: {sub}})
  }

  const items = computed(() => {
    return props.abilities.map(x => {
      const hope = props.goals.find(y => y.lrnerId === x.lrnerId && y.goalsDivCd === '01');
      // 현재 부점장이 작성한 코칭
      const coaching = props.goals.find(y => y.lrnerId === x.lrnerId && y.goalsDivCd === '02');
      return {
        ...x,
        hopeAbility: hope || {crseWorkDtlSn: 0},
        coaching: coaching || {crseWorkGoalsSn: 0},
      }
    });
  });

  const paging = isMobile.value ? reactive({
    pageNo: computed(() => route.query.pageNo ? parseInt(route.query.pageNo) : 1),
    pageSize: 10
  }) : reactive({
    pageNo: 1,
    pageSize: 10
  });

  const keyword = ref('');

  const filters = reactive({
    keyword: '',
    coaching: '',
    goal: ''
  });

  const toggles = reactive({
    coaching: false,
    goal: false
  })

  const targetItems = computed(() => {
    return items.value
    .filter(x => x.lrnerNm.includes(filters.keyword) || x.lrnerId.includes(filters.keyword))
    .filter(x => {
      // 코칭 등록된 직원
      if(filters.coaching === 'Y'){
        return x.coaching.crseWorkGoalsSn > 0;
      }else if(filters.coaching === 'N'){
        return x.coaching.crseWorkGoalsSn === 0;
      }
      return true;
    })
    .filter(x => {
      // 코칭 등록된 직원
      if(filters.goal === 'Y'){
        return x.hopeAbility.crseWorkDtlSn > 0;
      }else if(filters.goal === 'N'){
        return x.hopeAbility.crseWorkDtlSn === 0;
      }
      return true;
    });
  });

  const renderItems = computed(() => {
    return paginate(targetItems.value, paging.pageNo, paging.pageSize);
  });

  const hasMore = computed(() => items.value.length > renderItems.value.length);

  const currentQuery = computed(() => route.query);

  const pagingFunc = (pageNo) => {
    if (isMobile.value) {
      router.push({query: {...currentQuery.value, pageNo}})
    } else {
      paging.pageNo = pageNo;
    }

  }


  const searchItems = () => {
    filters.keyword = keyword.value;
    if (isMobile.value) {
      router.push({query: {pageNo: 1}});
    } else {
      paging.pageNo = 1;
    }
  }

  const searchKeyword = () => {
    filters.keyword = keyword.value;
    searchItems();
  }

  const coachingManagement = reactive({
    toggle: false,
    target: null
  })

  const manageGoal = (item) => {
    coachingManagement.target = item;
    coachingManagement.toggle = true;
  }

  const completedCoaching = (item) => {
    emit('completed', item);
  }

  const showCoaching = target => {
    emit('showCoaching', target);
  }



  return {
    subTab,
    paging,
    targetItems,
    renderItems,
    hasMore,
    keyword,
    filters,
    toggles,
    coachingManagement,

    pagingFunc,
    searchItems,
    searchKeyword,
    manageGoal,
    completedCoaching,
    closeToggles,
    showCoaching,
    changeSub,
  }
}

export const viceTalentTargetCoachingSetup = (props, {emit}) => {
  const store = useStore();

  const isMobile = computed(() => store.state.auth.isMobile);
  const session = computed(() => store.state.auth.session);

  const {showLoading, hideLoading, showConfirm, showMessage} = useAlert();
  const isReady = ref(false);
  // 다시한번 조회함...
  const goals = ref([]);

  const nowGoal = computed(() => {
    const now = goals.value.find(x => x.goalsDivCd === '00');
    return now ? now : {
      crseWorkDtlSn: 0
    };
  });

  const hopeGoal = computed(() => {
    const hope = goals.value.find(x => x.goalsDivCd === '01');
    return hope ? hope : {
      crseWorkDtlSn: 0
    };
  });

  // 내가 작성한 코칭만 처리
  const coaching = computed(() => {
    const coach = goals.value.find(x => x.goalsDivCd === '02');
    return coach ? coach : {
      crseWorkGoalsSn: 0
    };
  });

  const coachingCn = ref('');
  const coachingCnLen = computed(() => coachingCn.value.length);
  const isLoading = ref(false);

  const closeModal = () => {
    emit('update:toggle', false);
  }

  const saveCoaching = () => {
    if(isLoading.value) return;
    isLoading.value = true;

    const coachingParams = {
      goalsDivCd: '02',
      goalsCn: coachingCn.value,
      lrnerId: props.target.lrnerId,
      lrnerNm: props.target.lrnerNm,
      deptNm: props.target.deptNm,
    };

    const coachingSave = coaching.value.crseWorkGoalsSn > 0 ?
        store.dispatch(`talent/${ACT_UPDATE_LRN_CRSE_WORK_GOALS}`, {crseWorkGoalsSn: coaching.value.crseWorkGoalsSn, params: coachingParams}) :
        store.dispatch(`talent/${ACT_INSERT_LRN_CRSE_WORK_GOALS}`, coachingParams);

    coachingSave.then(res => {
      if(isSuccess(res)){
        showMessage(`코칭이 ${coaching.value.crseWorkGoalsSn > 0 ? '수정' : '등록'}되었습니다.`);
        const emitParams = {
          ...coachingParams,
          mdfrId: session.value.lrnerId,
          mdfrNm: session.value.lrnerNm,
        }
        // update
        if(coaching.value.crseWorkGoalsSn > 0){
          emitParams.crseWorkGoalsSn = coaching.value.crseWorkGoalsSn;
        }else{
          emitParams.crseWorkGoalsSn = res.crseWorkGoalsSn;
        }
        if (!isMobile.value) closeModal();

        emit('completed', emitParams);
      }else{
        showMessage('코칭등록 권한이 없습니다.');
        isLoading.value = false;
      }
    }).catch(e => {
      console.error(e);
      showMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
      isLoading.value = false;
    })
  }

  const clickSaveCoaching = () => {
    if(coachingCnLen.value > 0){
      showConfirm({
        title: `코칭${coaching.value.crseWorkGoalsSn > 0 ? '수정' : '등록'}`,
        text : `코칭을 ${coaching.value.crseWorkGoalsSn > 0 ? '수정' : '등록'}하시겠습니까?`,
        callback: saveCoaching
      });
    }
  }


  watch(() => coachingCn.value, () => {
    if(coachingCnLen.value > 300){
      coachingCn.value = coachingCn.value.substr(0, 300);
    }
  });

  showLoading();

  if(props.target && props.target.lrnerId){
    getListFunc(`talent/${ACT_GET_LRN_CRSE_WORK_GOAL_LIST_BY_LRNER_ID}`, props.target.lrnerId, goals, null, () => {
      const coach = goals.value.find(x => x.goalsDivCd === '02');
      // 코칭 내용 입력
      if(coach) coachingCn.value = coach.goalsCn;
      isReady.value = true;
      hideLoading();
    });
  }else{
    hideLoading();
  }

  return {
    session,
    isReady,
    nowGoal,
    hopeGoal,
    coaching,
    coachingCn,
    coachingCnLen,
    clickSaveCoaching,
    closeModal,
    timestampToDateFormat
  }

}
