const initTemplate = '<html xmlns:x="urn:schemas-microsoft-com:office:excel"><head><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>||sheetName||</x:Name><x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml></head><body>||table||</body></html>';
// const initTemplate = '<html xmlns:x="urn:schemas-microsoft-com:office:excel"><head><meta http-equiv="content-type" content="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8"><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>||sheetName||</x:Name><x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml></head><body>||table||</body></html>';

export const downloadExcelFileByItems = (fileName, sheetName, headers, bodies) => {
  const keys = headers.map(x => x.key);
  const excelHtml = initTemplate.replace('||sheetName||', sheetName || 'sheet1').replace('||table||', `<table><thead><tr>${headers.map(x => `<th>${x.name}</th>`).join('')}</tr></thead><tbody>${bodies.map((x, index) => `<tr>${keys.map(y => y === 'no' ? `<td>${index+1}</td>` : `<td>${x[y]}</td>`).join('')}</tr>`).join('')}</tbody></table>`);
  downloadExcelFile(fileName, sheetName, excelHtml)
}

export const downloadExcelFile = (fileName, sheetName, excelHtml) => {
  const blob = new Blob([excelHtml], {type: "application/csv;charset=utf-8;"});
  const anchor = window.document.createElement('a');
  anchor.href = window.URL.createObjectURL(blob);
  anchor.download = fileName;
  anchor.click();
  anchor.remove();
}


